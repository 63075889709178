@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';
@import './variables.scss';

@font-face {
  font-family: 'News Gothic';
  src: url('assets/fonts/news-gothic.otf') format('opentype');
  font-weight: normal;
}
$laps-typography: mat.define-typography-config(
  $font-family: 'News Gothic',
);

$laps-primary: mat.define-palette($laps-primary-palette, 900, 100, 900);
$laps-accent: mat.define-palette($laps-accent-palette);
$laps-warn: mat.define-palette($laps-warn-palette, 700, 100, 900);
$laps-theme: mat.define-light-theme(
  (
    color: (
      primary: $laps-primary,
      accent: $laps-accent,
      warn: $laps-warn,
    ),
    typography: $laps-typography,
  )
);

@include mat.all-component-themes($laps-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'News Gothic', Roboto, sans-serif;
}

.toast {
  &-success,
  &-error {
    @apply m-6 #{!important};
  }

  &-success {
    .mdc-snackbar__surface {
      @apply bg-green-200 #{!important};
    }

    .mdc-snackbar__label {
      @apply text-white-100 #{!important};
    }
  }

  &-error {
    .mdc-snackbar__surface {
      @apply bg-red-100 #{!important};
    }

    .mdc-snackbar__label {
      @apply text-red-800 #{!important};
    }
  }
}

.alert {
  @apply max-w-md p-3 mb-4 rounded;
  &-error {
    background: map.get($laps-warn, 100);
    color: map.get($laps-warn, 800);
  }

  &-info {
    background: map.get($laps-primary, 800);
    color: $laps-light-text;
  }
}

.filename-link {
  @apply relative break-all;

  &:hover {
    @apply cursor-pointer underline;
  }

  &::after {
    font-family: Material Icons, sans-serif;
    content: '\e89e';
    font-size: 1rem;
    position: absolute;
    margin-left: 0.5rem;
  }
}

.permits-font {
  @apply lg:text-lg sm:text-sm lg:leading-7 sm:leading-5 tracking-0.25px;
}

.ag-theme-material {
  font-family: 'News Gothic', Roboto, sans-serif !important;

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    @apply px-4;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    @apply h-full min-h-[275px];
  }

  .ag-ltr .ag-cell {
    @apply text-gray-400;
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-transparent;
  }

  .ag-row {
    @apply border-none;
  }

  .ag-row:hover {
    @apply bg-white-800;
  }

  .ag-header-cell:hover {
    @apply bg-transparent #{!important};
  }

  .ag-pinned-right-header,
  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
      .ag-cell-range-single-cell
    ) {
    @apply border-l-0;
  }

  .ag-icon-menu::before {
    content: '\f12a';
  }
}

.applications-table.ag-theme-material,
.admin-applications-table.ag-theme-material {
  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    @apply border-transparent;
  }

  .ag-header-cell-comp-wrapper {
    @apply w-auto;
  }

  .grid-paper-app-icon::after {
    content: url(assets/icons/paper-app.svg);
    @apply inline-block align-middle leading-normal ml-3;
  }
}

.sites-table.ag-theme-material {
  .grid-sterilized-app-icon::after {
    content: url(assets/icons/sterilized-app.svg);
    @apply inline-block align-middle leading-normal ml-3;
  }
}

.admin-applications-table.ag-theme-material {
  .grid-sterilized-app-icon::after {
    content: url(assets/icons/sterilized-app.svg);
    @apply inline-block align-middle leading-normal ml-3;
  }
}

.applications-table.ag-theme-material {
  .ag-header-cell:nth-child(9):hover,
  .ag-header-cell:nth-child(10):hover {
    @apply bg-transparent #{!important};
  }
}

// MDC MIGRATION CLASSES (TODO: REMOVE COMMENT AFTER MIGRATION)

// FORM FIELD (INPUT, TEXTAREA, SELECT)
.laps-readonly-form-field {
  pointer-events: none;

  .mdc-text-field--disabled.mdc-text-field--filled {
    @apply bg-transparent p-0;
  }

  .mdc-floating-label {
    @apply text-blue-1000 text-sm;
  }

  .mdc-text-field--disabled .mdc-text-field__input,
  .mat-mdc-select-disabled .mat-mdc-select-value {
    @apply mt-1 opacity-100;
    -webkit-text-fill-color: #000000;
    -webkit-opacity: 1;
  }

  .mdc-line-ripple {
    @apply hidden;
  }

  .mat-mdc-select-arrow-wrapper {
    @apply hidden;
  }
}

.laps-disabled-readonly-select-field {
  .mat-mdc-select-disabled {
    .mat-mdc-select-value {
      @apply text-black-100;
    }
  }

  .mat-mdc-floating-label {
    @apply text-blue-1000 text-sm;
  }

  .mat-mdc-select-arrow-wrapper,
  .mdc-line-ripple {
    @apply hidden;
  }
}

.laps-disabled-readonly-field {
  .mdc-text-field--disabled {
    @apply p-0 bg-transparent;

    .mdc-floating-label {
      @apply text-blue-1000 text-sm;
    }

    .mdc-text-field__input {
      @apply text-black-100;
      -webkit-text-fill-color: #000000;
      -webkit-opacity: 1;
    }

    & > .mdc-line-ripple {
      &::before {
        @apply hidden;
      }
    }
  }

  + .mat-form-field-disabled {
    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }
  }
}

.laps-search-form-field {
  .mat-mdc-text-field-wrapper {
    @apply p-0 bg-white-100;
  }

  .mat-mdc-form-field-flex {
    @apply px-4 py-0;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    @apply px-0 py-2 min-h-0;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    @apply text-gray-500;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    @apply border-gray-500;
  }

  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    @apply border-blue-1000;
  }
}

.laps-form-field {
  .mdc-text-field--filled {
    @apply bg-transparent p-0;
  }

  .mat-mdc-form-field-focus-overlay {
    @apply bg-transparent;
  }

  .mdc-text-field .mdc-floating-label {
    @apply text-sm;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    @apply text-blue-1000;
  }

  &:not(.mat-form-field-invalid) {
    .mdc-text-field--filled:not(.mdc-text-field--disabled)
      .mdc-line-ripple::before,
    .mdc-text-field--filled:not(.mdc-text-field--disabled):hover
      .mdc-line-ripple::before {
      @apply border-b-gray-600;
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
      .mdc-text-field--focused
    ):hover
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    @apply border-blue-1000;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    @apply p-0;
  }

  .mat-mdc-form-field-hint {
    @apply text-gray-500;
  }
}

// SELECT OPTIONS
.mdc-list-item__primary-text {
  @apply text-gray-500 min-w-0;
}

// CARD
.laps-card {
  .mat-mdc-card-content {
    @apply p-12;
  }

  .mat-mdc-card-content:last-child {
    @apply pb-12;
  }

  .mat-mdc-card-content:first-child {
    @apply pt-12;
  }
}

// CHECKBOX
.laps-checkbox-white-wrapper {
  .mat-mdc-checkbox .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate
      ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate='true']:enabled
      ~ .mdc-checkbox__background,
    &:hover
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background,
    &:hover
      .mdc-checkbox__native-control:enabled:indeterminate
      ~ .mdc-checkbox__background,
    &:hover
      .mdc-checkbox__native-control[data-indeterminate='true']:enabled
      ~ .mdc-checkbox__background,
    &:not(:disabled):active
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background,
    &:not(:disabled):active
      .mdc-checkbox__native-control:enabled:indeterminate
      ~ .mdc-checkbox__background,
    &:not(:disabled):active
      .mdc-checkbox__native-control[data-indeterminate='true']:enabled
      ~ .mdc-checkbox__background {
      @apply bg-white-100  border-white-100 w-5 h-5;
    }

    &:hover
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background,
    &:not(:disabled):active
      .mdc-checkbox__native-control:enabled:not(:checked):not(
        :indeterminate
      ):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      @apply bg-white-101 border-white-100 w-5 h-5;
    }

    .mdc-checkbox__native-control:enabled
      ~ .mdc-checkbox__background
      .mdc-checkbox__checkmark {
      @apply text-blue-1000;
    }
  }
}

// BUTTON
.mdc-button--outlined {
  @apply border border-blue-1000 #{!important};
}

// PROFILE MENU PANEL
.laps-profile-menu-panel {
  @apply min-w-[14rem] #{!important};

  .mat-mdc-menu-content {
    @apply p-0 border-b-[6px] border-solid border-cyan-500;
  }

  .mat-mdc-menu-item .mdc-list-item__primary-text {
    @apply w-full;
  }

  .mat-mdc-menu-item:hover {
    @apply bg-cyan-100;
  }

  .active-menu {
    @apply border-l-[6px] border-solid border-y-0 border-r-0 border-cyan-500;
  }

  .inactive-menu {
    @apply border-l-[6px] border-solid border-y-0 border-r-0 border-transparent;
  }
}

// MODAL
.mat-mdc-dialog-container .mdc-dialog__surface {
  @apply px-2 py-6;
}

.mdc-dialog__actions {
  padding: 0 24px !important;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
  @apply p-0 #{!important};
}

.paper-application-wizard,
.archive-wizard {
  .mat-horizontal-stepper-header-container {
    @apply 3xl:px-44 2xl:px-36 lg:px-20 px-4 3xl:mb-8 2xl:mb-6 sm:mb-5 bg-white-400;
  }

  .mat-horizontal-stepper-header-container
    .mat-step-header.cdk-program-focused {
    @apply bg-transparent;
  }

  .mat-step-header .mat-step-icon-state-number {
    @apply bg-white-600;
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-edit {
    @apply bg-blue-1000;
  }

  .mat-stepper-horizontal-line {
    @apply mx-4 #{!important};
  }

  .mat-step-icon .mat-step-icon-content {
    @apply text-gray-500;
  }

  .mat-step-icon-selected .mat-step-icon-content,
  .mat-step-icon.mat-step-icon-state-done .mat-step-icon-content,
  .mat-step-icon.mat-step-icon-state-edit .mat-step-icon-content {
    @apply text-white-100;
  }

  .mat-step-header .mat-step-label {
    @apply text-gray-500;
  }

  .mat-step-label-selected .mat-step-text-label {
    @apply text-blue-1000;
  }

  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    @apply bg-green-100;
  }

  .mat-horizontal-content-container {
    @apply 3xl:px-44 2xl:px-36 lg:px-20 px-4 #{!important};
  }

  &-cta-group {
    @apply grid justify-center;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  }
}

.payment {
  .mat-expansion-panel-body {
    padding: 24px 0 !important;
  }
}

.new-lines {
  white-space: pre-line !important;
}

.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-tooltip-panel {
      .blue-arrow-tooltip.mdc-tooltip {
        &::before {
          content: '';
          position: absolute;
          border-radius: 0;
          border-style: solid;
          width: 1rem;
          height: 1rem;
          z-index: 100;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          border-width: 18px 18px 18px 0;
          border-color: transparent map.get($laps-primary, 800) transparent
            transparent;
        }

        .mdc-tooltip__surface {
          margin-left: 0.9rem;
          position: relative;
          color: $laps-light-text;
          background-color: map.get($laps-primary, 800);
          font-size: 14px;
          padding: 10px;
          max-width: 50ch;
          border-radius: 5px;
        }
      }
    }
  }
}
